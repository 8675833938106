<template>
  <div class="scheme-contain">
    <!-- <Tabs value="1" :animated="false">
      <Tab-pane label="疾病" name="1">
        <illness-table></illness-table>
      </Tab-pane>
      <Tab-pane label="家族遗传史" name="2">
        <illness-heredity></illness-heredity>
      </Tab-pane>
      <Tab-pane label="手术外伤史" name="3">
        <trauma></trauma>
      </Tab-pane>
      <Tab-pane label="主诉" name="4">
        <illness-main></illness-main>
      </Tab-pane>
    </Tabs> -->
    <div class="sickness-contain">
      <ul class="horizontal-sub-nav clearfix">
        <li><router-link :to="{name: 'main'}">主诉</router-link></li>
        <li><router-link :to="{name: 'illness'}">疾病</router-link></li>
        <li><router-link :to="{name: 'hereditary'}">家族遗传史</router-link></li>
        <li><router-link :to="{name: 'trauma'}">手术外伤史</router-link></li>
      </ul>
    </div>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script type="text/ecmascript-6">
</script>
<style lang="css" scoped>
.sickness-contain {
  text-align: center;
  margin-bottom: 45px;
}
.horizontal-sub-nav {
  display: inline-block;
}
.horizontal-sub-nav > li {
  float: left;
  display: inline-block;
  margin-right: 30px;
}
.horizontal-sub-nav a {
  color: #333;
  padding: 10px 26px;
}
.horizontal-sub-nav a.active {
  color: #fdad00;
  border-bottom: 3px solid #fdad00;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to  {
  opacity: 0
}
</style>
